.v3__custom-select-flex {
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
}

.v3__custom-select__container {
  display: flex;
  flex-flow: column nowrap;
}

.v3__custom-select__label {
  margin-bottom: var(--space-xxxs);
  color: $color-grayscale-900;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h6);
  white-space: nowrap;
}

.v3__custom-select__input-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $custom-select-container-height;
  background-color: $color-background-container;
  border: 2px solid $color-border-default;
  border-radius: 4px;

  .v3__badge {
    position: absolute;
    margin-left: var(--space-xxs);
  }
}

.v3__custom-select__arrow {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  width: 12px;
  height: 12px;
  background-color: $color-background-container;
  box-shadow: 0 0 2px 4px white;
  cursor: pointer;
  transition: 300ms;
}

.v3__custom-select__arrow-flipped {
  rotate: 180deg;
}

.v3__custom-select__input {
  width: 100%;
  height: 100%;
  padding: 0 var(--space-xxs);
  background-color: transparent;
  border: none;
  cursor: pointer;

  &.v3__custom-select__input--is-open {
    border-radius: 4px;
    outline: 2px solid $color-border-focus;
  }

  &::placeholder {
    color: $color-grayscale-600;
  }

  // hide the placeholder text when editing
  &.v3__custom-select__input--is-open::placeholder,
  &.v3__custom-select__input--editable:focus::placeholder {
    color: transparent;
  }

  &.v3__custom-select__input--has-badge {
    padding-left: 32px;
  }
}

// This is just used to set a positioning context so that we can force
// v3__custom-select__selections to overlap the elements below it with position:
// absolute without knocking it out of place. We can't just use position:
// relative, because it still occupies space when we do.
.v3__custom-select__selections-bounding-box {
  position: relative;
}

.v3__custom-select__selections-container {
  position: absolute;
  z-index: 99; // overlap flash messages
  min-width: 100%;
  max-width: 200%;
  max-height: 400px;
  margin: 0;
  padding: var(--space-xxs) 0;
  overflow: auto;
  list-style-type: none;
  background-color: $color-background-container;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgb(0 19 87 / 0.1);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.v3__custom-select__selections-container.v3__custom-select__direction-up {
  transform: translateY(calc(-100% - 32px));
}

.v3__custom-select__selections-container.v3__custom-select__closed {
  display: none;
  opacity: 0;
  pointer-events: none;

  &.v3__custom-select__direction-down {
    transform: translate3d(0, -8px, 0);
  }

  &.v3__custom-select__direction-up {
    transform: translate3d(0, calc(-100% - 32px + 8px), 0);
  }
}

.v3__custom-select__chips {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xxxs);
  padding: var(--space-xxs);
}

.v3__custom-select__selections-container-list {
  margin: 0;
  padding: 0;
}

.v3__custom-select__selection-exclude_btn {
  margin-left: auto; // right-align
  line-height: 0;
  opacity: 0;

  .v3__button {
    // use negative pixels to avoid blowing out the container height
    margin: -2px 0 -2px 1rem;
  }
}

.v3__custom-select__selection {
  display: flex;
  align-items: flex-start;
  padding: var(--space-xxs) var(--space-xs);
  cursor: pointer;

  &:hover {
    .v3__custom-select__selection-icon-excluded {
      color: $color-grayscale-900;
    }

    .v3__custom-select__selection-exclude_btn {
      opacity: 1;
    }
  }
}

.v3__custom-select__selection__excluded {
  background: $color-brand-150;
}

.v3__custom-select__selection__unselected {
  font-weight: var(--font-weight-regular);

  &:hover {
    color: $color-brand-600;
    background: $color-brand-100;
  }
}

.v3__custom-select__selection__focused {
  color: $color-brand-600;
  background: $color-brand-150;
}

.v3__custom-select__selection-text {
  word-break: break-word;
  user-select: none;
}

.v3__custom-select__selection-icon {
  display: flex;
  height: 16px;
  margin-right: var(--space-xxs);
  background-color: $color-background-container;
  border-radius: 4px;
}

.v3__custom-select__selection-icon-selected {
  color: $color-brand-600;
}

.v3__custom-select__selection__selected {
  background-color: $color-brand-150;

  &:hover {
    .v3__custom-select__selection-icon-selected {
      color: $color-brand-600;
    }
  }
}

.v3__custom-select__clear {
  color: $color-brand-600;
}

.v3__custom-select__disabled {
  .v3__custom-select__label {
    opacity: 0.6;
  }

  .v3__custom-select__selection {
    opacity: 0.6;
  }
}
