.header__menu-btn {
  &:hover {
    background: $color-blue-800;
  }
}

.header__menu-btn--open {
  background: $color-blue-800;

  &:hover {
    background: $color-blue-700;
  }
}

.header__menu-btn--account {
  &:hover {
    $blue-850: #002e58;

    background-color: $blue-850;
  }
}

// reset
.v3__header {
  h4,
  h5 {
    color: $color-grayscale-0;
  }
}
