.v3__secondary-toggle {
  padding: 2px;
  background-color: var(--color-grayscale-400);
  border-radius: var(--corner-radius-sm);
}

.v3__secondary-toggle__button {
  display: flex;
  margin: 0;
  color: var(--color-grayscale-700);
  border-radius: var(--corner-radius-sm);
  cursor: pointer;

  &:focus-visible {
    border: 1px solid var(--color-grayscale-700);
    outline: none;
  }
}

.v3__secondary-toggle__button--selected {
  background-color: var(--color-grayscale-0);
}

.v3__secondary-toggle__button--unselected {
  background-color: var(--color-grayscale-400);

  &:hover {
    background-color: var(--color-grayscale-300);
  }
}
