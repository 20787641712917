.v3__table-wrapper {
  position: relative;
  z-index: 0;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: visible;
  border-radius: var(--corner-radius-m);
}

.v3__table-wrapper--no-overflow {
  overflow-x: visible;
}

.v3__table-wrapper--fill-height {
  height: 100%;
}

.v3__table-wrapper--default {
  background: $color-background-container;
  border: 1px solid var(--color-grayscale-300);
}

.v3__table-inner-layout-container {
  display: flex;
  flex-basis: calc(100% - 264px);
  flex-grow: 1;
  overflow-y: visible;
}

.v3__table {
  flex-grow: 1;
  margin: 0;
  font-size: var(--font-size-h6);
  background: transparent;
  border: none;
  border-collapse: collapse;
}

.v3__table--dark,
.v3__table--dark td {
  color: $color-grayscale-0;
}

.v3__table-cell {
  padding: 15px;
  font-size: var(--font-size-h6);
  line-height: 16px;
  text-align: left;
  border: none;
}

.v3__table-body-row--linkable {
  // hack to get full-height cells: https://stackoverflow.com/a/34781198
  height: 1px;
}

.v3__table-cell--linkable {
  height: inherit;
  padding: 0;
}

.v3__table-cell--link {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 15px;
}

.v3__table-body .v3__table-body-row {
  background: $color-grayscale-0;
}

.v3__table-body--dark {
  background: $color-navy-900;
}

.v3__table-body--dark .v3__table-body-row {
  background: $color-blue-900;
}

.v3__table-body-row--selected .v3__table-cell {
  background: $color-brand-100;
}

.v3__table .v3__table-cell--expandable {
  position: sticky;
  right: 0;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  padding: 20px 10px;
  white-space: nowrap;
  border-left: none;
  box-shadow: 0 4px transparent, -4px 0 4px $color-grayscale-400;
}

.v3__table .v3__table-cell--expandable svg {
  color: $color-brand-500;
}

.v3__table .v3__table-body-row--selected .v3__table-cell--expandable {
  box-shadow: 0 4px 4px $color-brand-200, -4px 0 4px $color-brand-200;
}

.v3__table .v3__table-head .v3__table-cell--expandable svg {
  color: $color-grayscale-900;
}

.v3__table .v3__table-body-row--selected .v3__table-cell--expandable svg {
  color: $color-grayscale-900;
}

.v3__table .v3__table-body .v3__table-cell--expandable:hover svg {
  color: $color-grayscale-900;
}

.v3__table-head {
  z-index: 1;
  white-space: nowrap;
  border-top: none;
  border-radius: 0;
}

.v3__table-head--default {
  color: $color-grayscale-900;
  background: $color-grayscale-0;
}

.v3__table-head--dark {
  color: $color-grayscale-400;
  background: $color-brand-800;
}

.v3__table-cell--head {
  position: sticky;
  top: 0;
  font-weight: var(--font-weight-bold);
}

.v3__table-head--default .v3__table-cell--head {
  background: $color-grayscale-0;
  box-shadow: inset 0 -1px 0 0 var(--color-grayscale-400);
}

.v3__table-cell--head--text {
  display: inline-flex;
  flex-basis: 10px;
}

.v3__table-cell--body {
  font-weight: var(--font-weight-regular);
}

.v3__table-cell--expandable-wrapper {
  display: inline-block;
  padding: 0 20px;
  font-weight: var(--font-weight-bold);
}

.v3__table-body-row--expandable {
  display: none;
  width: 100%;
  margin-left: 10px;
  padding: 10px;
  background: $color-grayscale-0;
}

.v3__table-body-row--expandable--selected {
  display: block;
  width: auto;
  background: $color-grayscale-0;
  border: 1px solid $color-grayscale-400;
}

.v3__table-cell--head--right-aligned,
.v3__table-cell--right-aligned,
.v3__table-cell--expandable-header--right-aligned,
.v3__table-cell--expandable-data--right-aligned {
  text-align: right;
}

.v3__table-cell--head--top-aligned,
.v3__table-cell--top-aligned,
.v3__table-cell--expandable-header--top-aligned,
.v3__table-cell--expandable-data--top-aligned {
  vertical-align: top;
}

.v3__table-cell--head--bottom-aligned,
.v3__table-cell--bottom-aligned,
.v3__table-cell--expandable-header--bottom-aligned,
.v3__table-cell--expandable-data--bottom-aligned {
  vertical-align: bottom;
}

// Even rows and immediately following subrows have gray background
.v3__table :nth-child(2n of .v3__table-body-row--default),
.v3__table
  :nth-child(2n of .v3__table-body-row--default)
  + .v3__table-body-subrow {
  background: $color-grayscale-100;
}

.v3__table-body-row--dark:not(:last-child) {
  border-bottom: 1px solid $color-navy-900;
}

.v3__table--expandable .v3__table-body-row:nth-child(4n + 3) {
  background: $color-grayscale-100;
}

.v3__table-body-row--selected
  .v3__table-cell:not(:last-child):not(:first-child) {
  box-shadow: 4px 4px 4px $color-brand-200;
}

.v3__table--expandable
  .v3__table-body-row--selected:nth-child(4n + 3)
  .v3__table-cell {
  background: $color-brand-100;
}

.v3__table-body-row--clickable,
.v3__table-cell--clickable {
  cursor: pointer;
}

.v3__table-body-row.v3__table-body-row--clickable,
.v3__table-body-row.v3__table-body-row--linkable {
  transition: background-color 100ms;

  &:hover {
    background-color: $color-blue-100;

    // first shadow is 1px all-around, second makes 2px on left side
    box-shadow: inset 0 0 0 1px $color-blue-600,
      inset 1px 0 0 1px $color-blue-600;
  }
}

.v3__table-body-row--dark.v3__table-body-row--clickable,
.v3__table-cell--dark.v3__table-cell--clickable {
  &:hover {
    $blue-850: #002e58;

    background-color: $blue-850;
  }
}

.v3__table-foot-row--default {
  background: $color-brand-100;
  border-top: 1px solid $color-grayscale-400;
}

.v3__table-foot-row--dark {
  background: $color-blue-900;
}

.v3__table-cell--footer {
  font-weight: var(--font-weight-regular);
}

.v3__table-cell--footer--default {
  border-top: solid $color-grayscale-400 1px;
}

.v3__table-toolbar {
  border-bottom: 1px solid $color-grayscale-300;
}

.v3__table-toolbar-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h4);
  line-height: 16px;
}

.v3__table-toolbar-search {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .v3__input-label {
    width: 100%;
  }

  .v3__table-search {
    height: 40px;
    border-radius: 50px;
  }

  .v3__input-icon--leading {
    color: $color-grayscale-600;
  }
}

.v3__table-toolbar-filters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xxxs);
  align-items: flex-end;
  margin: calc(-1 * var(--space-xxs)) 0;
}

.v3__table-toolbar-filters-trailing-button,
button.v3__table-toolbar-filters-trailing-button {
  margin: 0;
  padding: var(--space-xxxs);
  color: $color-grayscale-900;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small-body);
  line-height: 1;
  white-space: nowrap;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: none;
}

.v3__table-toolbar-filters-trailing-button:hover {
  text-decoration: underline;
}

.v3__table--pagination {
  border-top: 1px solid $color-grayscale-300;
}

@include v3-container-mobile {
  .v3__table-toolbar {
    flex-wrap: wrap;
  }

  .v3__table-toolbar-filters {
    flex-wrap: nowrap;
    order: 9;
    width: 100%;
    overflow-x: auto;
  }
}

.v3__table-layout-row-wrap {
  display: flex;
  flex-flow: row nowrap;
}

.v3__table-layout-row-wrap--fill-height {
  height: 100%;
}

.v3__table-layout-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.v3__table-layout-column--no-overflow {
  overflow-x: visible;
}

.v3__drawer-container {
  width: 30%;
  min-width: 200px;
  border-left: 1px solid $color-grayscale-300;
  border-radius: 0;
}
